import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import styled from "styled-components";

const ServiceHero = ({ title, backgroundImage, bgPos }) => {
	const backgroundPos = bgPos || "center";
	const isDarken = true;
	const pluginImage = getImage(backgroundImage);
	const StyledBackground = styled(BgImage)`
		&::before,
		&::after {
			filter: brightness(
				${({ isDarken }) => {
					return isDarken ? "50%" : "100%";
				}}
			);
		}
	`;
	return (
		<StyledBackground
			style={{ backgroundPosition: backgroundPos }}
			isDarken={isDarken}
			key={isDarken ? `dark` : `light`}
			Tag="section"
			className="py-md-8 py-5"
			image={pluginImage}
			alt="Hero Image"
		>
			<Container>
				<Row className=" align-items-center">
					<Col className="text-center">
						<h1 className="text-white display-4" id="services-hero-title">
							{title}
						</h1>
					</Col>
				</Row>
			</Container>
		</StyledBackground>
	);
};

export default ServiceHero;
