import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";

const TextSection = ({ title, text, hideBtn, sHeading }) => {
	return (
		<Container>
			<Row className="justify-content-center">
				<Col
					style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)" }}
					className="p-lg-6 px-3 py-4 px-4  bg-white"
					xl={10}
				>
					<Row>
						<Col>
							<h5 className={`${sHeading ? "" : "d-none"} text-center mb-3`}>
								{sHeading}
							</h5>
							<h1 className="text-center mb-1">{title}</h1>
							<hr
								className="bg-primary mx-auto mt-0"
								style={{ height: "2px", width: "6rem" }}
							/>
						</Col>
					</Row>
					<Row className="pt-4">
						<Col>
							{text}
							<div className="text-center">
								<Button
									className={` cta-btn w-100 w-md-auto mt-3 px-4 fw-bold ${
										hideBtn ? "d-none" : ""
									}`}
									variant="primary"
									as={Link}
									to="/contact-us"
								>
									Get a quote
								</Button>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default TextSection;
