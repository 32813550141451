import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useStaticQuery, graphql } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image";

const Certifications = () => {
	const data = useStaticQuery(graphql`
		query {
			DIImg: wpMediaItem(title: { eq: "Domestic-Installer-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			RCPImg: wpMediaItem(title: { eq: "Registered-Competent-Person-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			ECAImg: wpMediaItem(title: { eq: "ECA-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			ESRImg: wpMediaItem(title: { eq: "Electrical-Safety-Register-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			TMImg: wpMediaItem(title: { eq: "Trustmark-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			IDEImg: wpMediaItem(
				title: { eq: "Institution-of-Diagnostic-Engineers-Logo" }
			) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			DLImg: wpMediaItem(title: { eq: "Dimplex-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
		}
	`);

	const DIImg = data.DIImg?.localFile.childImageSharp.gatsbyImageData;
	const RCPImg = data.RCPImg?.localFile.childImageSharp.gatsbyImageData;
	const ECAImg = data.ECAImg?.localFile.childImageSharp.gatsbyImageData;
	const ESRImg = data.ESRImg?.localFile.childImageSharp.gatsbyImageData;
	const TMImg = data.TMImg?.localFile.childImageSharp.gatsbyImageData;
	const IDEImg = data.IDEImg?.localFile.childImageSharp.gatsbyImageData;
	const DLImg = data.DLImg?.localFile.childImageSharp.gatsbyImageData;

	return (
		<Container>
			<Row className="d-none d-md-block">
				<Col className="d-flex align-items-center justify-content-center">
					<GatsbyImage
						className="me-5"
						image={DIImg}
						alt={data.DIImg?.altText}
					/>
					<GatsbyImage
						className="me-5"
						image={RCPImg}
						alt={data.RCPImg?.altText}
					/>
					<GatsbyImage
						className="me-5"
						image={ECAImg}
						alt={data.ECAImg?.altText}
					/>
					<GatsbyImage
						className="me-5"
						image={ESRImg}
						alt={data.ESRImg?.altText}
					/>
					<GatsbyImage
						style={{ maxWidth: "163px" }}
						className="me-5"
						image={TMImg}
						alt={data.TMImg?.altText}
					/>
					<GatsbyImage
						className="me-5"
						image={IDEImg}
						alt={data.IDEImg?.altText}
					/>
					<GatsbyImage image={DLImg} alt={data.DLImg?.altText} />
				</Col>
			</Row>
			<Row className="d-md-none justify-content-center">
				<Col className="pb-3" md={4}>
					<Row>
						<Col className="d-flex align-items-center" xs={4}>
							<GatsbyImage image={DIImg} alt={data.DIImg?.altText} />
						</Col>
						<Col className="d-flex align-items-center" xs={4}>
							<GatsbyImage image={RCPImg} alt={data.RCPImg?.altText} />
						</Col>
						<Col className="d-flex align-items-center" xs={4}>
							<GatsbyImage image={ECAImg} alt={data.ECAImg?.altText} />
						</Col>
					</Row>
				</Col>
				<Col className="pb-3" md={4}>
					<Row>
						<Col className="d-flex align-items-center" xs={4}>
							<GatsbyImage image={ESRImg} alt={data.ESRImg?.altText} />
						</Col>
						<Col className="d-flex align-items-center" xs={4}>
							<GatsbyImage image={TMImg} alt={data.TMImg?.altText} />
						</Col>
						<Col className="d-flex align-items-center" xs={4}>
							<GatsbyImage image={IDEImg} alt={data.IDEImg?.altText} />
						</Col>
					</Row>
				</Col>
				<Col md={4}>
					<Row className="justify-content-center">
						<Col className="d-flex align-items-center " xs={4}>
							<GatsbyImage
								className="mx-auto"
								image={DLImg}
								alt={data.DLImg?.altText}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default Certifications;
