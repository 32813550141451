import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Layout from "../../components/layout";
import TextSection from "../../components/text-section";
import ServiceHero from "../../components/service-hero";
import ReviewSection from "../../components/review-section";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Certifications from "../../components/certifications";

const MaintenancePage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "EICR-Image-New" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Services",
				item: {
					url: `${siteUrl}/services`,
					id: `${siteUrl}/services`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Electrical Installation Condition Report",
				item: {
					url: `${siteUrl}/services/electrical-installation-condition-report`,
					id: `${siteUrl}/services/electrical-installation-condition-report`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Electrical Installation Condition Report | Denis Bonnici Electricians Ltd"
				description="This is a detailed health check of your property testing circuits and accessories to provide a detailed report."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/services/electrical-installation-condition-report`,
					title:
						"Electrical Installation Condition Report | Denis Bonnici Electricians Ltd",
					description:
						"This is a detailed health check of your property testing circuits and accessories to provide a detailed report.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@denisbonnici",
					site: "@denisbonnici",
					cardType: "summary",
				}}
			/>
			<ServiceHero
				bgPos="top"
				backgroundImage={heroImg}
				title="Electrical Installation Condition Report (EICR)"
			/>
			<section className="py-lg-7 pb-4">
				<TextSection
					title="Electrical Installation Condition Report"
					text={
						<span>
							<p>
								This is a detailed health check of your property testing
								circuits and accessories to provide a detailed report.
							</p>
							<p>
								The electrics will be checked/tested against the UK standard for
								the safety of electrical installations in accordance with
								BS7671.
							</p>
							<p>
								If there are any dangerous or potentially dangerous conditions
								are found, the overall assessment of the electrical installation
								will be unsatisfactory. Remedial action would be required to
								remove any potential risk to premises/people. An estimate would
								then be submitted accordingly.
							</p>
							<p className="fw-bold">
								How often should this report be carried out?
							</p>
							<p>
								Your electrics should be inspected and tested at regular
								intervals dependant on location as noted in the BS7671, Guidance
								note 3: Inspection and Testing {"(IET)"}.
							</p>
							<p>
								Recommendations for homeowners are once every 10 years or on
								buying and selling a property.
							</p>
						</span>
					}
				/>
			</section>
			<section>
				<TextSection
					title="Electrical Safety Standards in the Private Rented Sector"
					text={
						<span>
							<p>
								As of 1st April 2021 it is now law to ensure that all rental
								properties have an Electrical Installation Condition Report
								{"(EICR)"} in place. For further details:
							</p>
							<p>
								<a
									className="text-dark text-decoration-underline mw-90"
									target="_blank"
									rel="noreferrer"
									href="https://www.rla.org.uk/landlord/guides/responsibilities/electrical_inspections.shtml"
								>
									https://www.rla.org.uk/landlord
									<br className="d-md-none" />
									/guides/responsibilities
									<br className="d-md-none" />
									/electrical_inspections.shtml
								</a>
							</p>
							<p>
								If you have an EICR that has been carried out within the last 5
								years, and all remedial works performed with written evidence,
								then you are covered, but you must ensure this is updated every
								5 years {"(or less if Electrician has concerns)"}, to remain
								compliant to the new law.
							</p>

							<p>
								This report must be carried out by a qualified Electrician.
								Essential remedial works highlighted within this report must be
								rectified and written evidence kept as proof of work done. These
								remedial works must be completed within 28 days, or if deemed
								dangerous, as soon as practically possible.
							</p>
							<p>
								{" "}
								A copy of this report should be given to your Tenant within 28
								days, either by the Landlord or Agent. If requested a copy may
								need to be given to your local authority within 7 days. If you
								do not comply with these new regulations the local authority
								will issue an enforcement notice and possible fines.
							</p>
							<p>
								<span className="fw-bold">Denis Bonnici Electricians</span> Ltd
								would perform the appropriate tests to conform to the new
								regulations. Any essential remedial works highlighted on the
								report would be discussed with the Landlord/Agent and an
								estimate provided. Once the remedial works have been carried out
								we would re-issue the EICR as satisfactory and present a
								detailed invoice of works carried out.
							</p>
							<p>
								<span className="fw-bold">PRICING:</span> The cost of performing
								an EICR would depend on the number of circuits:
							</p>
							<p>
								Up to and including 5 x circuits = £120 + Vat. Any additional
								circuits @£15 each + Vat.
							</p>
							<p>
								A visual inspection of smoke alarms would also be done at the
								same time to ensure they are within date {"(if date on unit)"}.
							</p>
							<p>
								<span className="fw-bold">
									The Landlords and Tenant Act 1985
								</span>{" "}
								requires landlords of properties with short leases to keep the
								electrical wiring in good repair and working order.
							</p>
							<p>
								Insurance companies may also want evidence that properties are
								being maintained and tested in accordance with this act.
							</p>
							<p className="text-center mt-4 fs-5 fw-bold">
								Allow an average of 3-4 hours on site for testing
							</p>
						</span>
					}
				/>
			</section>
			<section className="py-md-7 py-4">
				<TextSection
					title="Additional Certifications"
					text={
						<span>
							<p>
								<span className="fw-bold">MINOR WORKS CERTIFICATE:</span>{" "}
								Extension of circuits.
							</p>
							<p>
								<span className="fw-bold">
									ELECTRICAL INSTALLATION CERTIFICATE:
								</span>{" "}
								New installations, i.e. Consumer units and new circuits.
							</p>
							<p>
								{" "}
								<span className="fw-bold">
									PART P BUILDING CONTROL CERTIFICATION:
								</span>{" "}
								Actioned by us through ELECSA as appropriate to regulation.
							</p>
							<p>
								<span className="fw-bold">PAT TESTING:</span> Frequency as
								highlighted within the Code of Practice for in-service
								inspection and testing of electrical equipment {"(IET)"}
							</p>
						</span>
					}
				/>
			</section>
			<section className="pt-6 pb-7 ">
				<Certifications />
			</section>
			<section className="pb-md-7 pb-4">
				<ReviewSection />
			</section>
		</Layout>
	);
};

export default MaintenancePage;
